<template>
  <div>
    <el-card v-for="(item,index) in getSelectProducts" :key="index" style="margin:5px 10px;" :body-style="{padding:'5px'}">
      <div style="display: flex;">
        <div style="border: solid #1989fa 2px;border-radius: 10px;display: flex;justify-content: center; align-items: center;font-weight: 900;width: 85px;height: 85px;">{{item.productType}}</div>
        <div style=" text-align:center;flex: 1;display: flex;flex-direction: column;justify-content: space-around;">
          <div style="text-align:center;">{{item.productName}}</div>
          <div style="display: flex;justify-content: center;align-items: center;font-weight: bolder; margin-top: 10px; color: red;">
            X{{item.selectCount}}
          </div>
        </div>
      </div>
    </el-card>
    <van-field label="联系人" placeholder="请输入联系人" v-model="postData.erp_Shipment.contactName"></van-field>
    <van-field label="手机号" type="tel" placeholder="请输入手机号" v-model="postData.erp_Shipment.contactPhone"></van-field>
    <van-field label="地区" :value="postData.erp_Shipment.area" is-link readonly @click="showPPP=true" placeholder="请选择地区"></van-field>
    <van-field label="详细地址" placeholder="请输入详细地址" v-model="postData.erp_Shipment.address"></van-field>
    <div style="margin:10px 10px">
      <van-button round type="info" block @click="handleSubmitShipment">提交订单</van-button>
    </div>
    <van-popup v-model="showPPP" round position="bottom">
      <van-area :area-list="areaList" @cancel="showPPP=false" @confirm="handleAddressConfirm"></van-area>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import { areaList } from "@vant/area-data";

Vue.use(Vant);

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      areaList: areaList,
      showPPP: false,
      postData: {
        erp_Shipment: {
          contactName: "",
          contactPhone: "",
          area: "",
          areaCode: "",
          address: "",
        },
      },
    };
  },
  methods: {
    handleAddressConfirm(v) {
      let that = this;
      that.showPPP = false;
      if (v[0] && v[1] && v[0].name == v[1].name) {
        that.postData.erp_Shipment.area = v[0].name + "/" + v[2].name;
      } else {
        that.postData.erp_Shipment.area =
          v[0].name + "/" + v[1].name + "/" + v[2].name;
      }
      that.postData.erp_Shipment.areaCode = v[2].code;
    },
    handleSubmitShipment() {
      let that = this;
      that.$dialog
        .confirm({ title: "提示", message: "确定要提交吗？" })
        .then(function () {
          that.postData.lesd = [];
          that.getSelectProducts.forEach((element) => {
            that.postData.lesd.push({
              productId: element.id,
              count: element.selectCount,
            });
          });
          that.axios
            .post("Shipment/SubmitShipment", that.postData)
            .then(function (response) {
              if (response.data.code == 101) {
                that.$notify({ type: "success", message: "提交成功" });
                window.location.href = "/WapMyShipMent";
              }
            });
        })
        .catch(function () {});
    },
  },
  computed: { ...mapGetters(["getSelectProducts"]) },
};
</script>

<style>
</style>